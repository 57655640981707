define("discourse/plugins/discourse-evidence/discourse/templates/connectors/user-preferences-nav-under-interface/evidence_uploader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="user-nav__preferences-chat">
    <LinkTo @route="evidence_uploader">
        <svg class="fa d-icon d-icon-envelope svg-icon svg-string" xmlns="http://www.w3.org/2000/svg"><use href="#envelope"></use></svg>
        <span>マッチング情報登録</span>
    </LinkTo>
  </li>
  
  */
  {
    "id": "dEk86gBa",
    "block": "[[[10,\"li\"],[14,0,\"user-nav__preferences-chat\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@route\"],[\"evidence_uploader\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"svg\"],[14,0,\"fa d-icon d-icon-envelope svg-icon svg-string\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[10,\"use\"],[14,6,\"#envelope\"],[12],[13],[13],[1,\"\\n      \"],[10,1],[12],[1,\"マッチング情報登録\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"li\",\"link-to\",\"svg\",\"use\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-evidence/discourse/templates/connectors/user-preferences-nav-under-interface/evidence_uploader.hbs",
    "isStrictMode": false
  });
});